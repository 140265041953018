import {Component, OnInit} from '@angular/core';
import {POSTS_MODULE} from '../core/posts.module';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
    public blogPosts = [];

    constructor() {
    }

    ngOnInit() {
        this.blogPosts = POSTS_MODULE;
    }

}
