// import {HttpModule} from '@angular/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {MaterialModule} from './materials.module';
import {PostsModule} from './posts.module';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {STANDARD_DIALOGS} from './dialogs';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        // HttpModule,
        MaterialModule,
        PostsModule,
        ReactiveFormsModule,
        RouterModule
    ],
    declarations: [
        ...STANDARD_DIALOGS,
    ],
    providers: [],
    exports: [
        CommonModule,
        FormsModule,
        // HttpModule,
        MaterialModule,
        PostsModule,
        ReactiveFormsModule,
        RouterModule,
    ],
    entryComponents: [
        ...STANDARD_DIALOGS,
    ]
})
export class CoreModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: CoreModule,
            providers: [],
        };
    }

    public static forChild(): ModuleWithProviders {
        return {
            ngModule: CoreModule
        };
    }
}
