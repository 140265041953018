import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from './home/home.component';
import {AboutComponent} from './about/about.component';
import {BlogComponent} from './blog/blog.component';
import {ContactComponent} from './contact/contact.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {LayoutComponent} from './layout/layout.component';
import {PostComponent} from './post/post.component';

export const ROUTES: Routes = [
    {
        path: 'Home',
        pathMatch: 'full',
        redirectTo: '',
    },
    {
        path: '',
        component: LayoutComponent,
        // resolve: {layout: LayoutResolver},
        children: [
            {
                path: '',
                component: HomeComponent
            },
            {
                path: 'about',
                component: AboutComponent
            },
            {
                path: 'blog',
                component: BlogComponent
            },
            {
                path: 'blog/:slug',
                component: PostComponent
            },
            {
                path: 'contact',
                component: ContactComponent
            },
            {
                path: '**',
                component: NotFoundComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(ROUTES)
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
