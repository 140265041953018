import {Component, OnInit} from '@angular/core';

import {FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl} from '@angular/forms';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {StandardDialogComponent} from '../core/dialogs';
import {Observable} from 'rxjs';

export interface ResponseObject {
    id: string;
    request_id: string;
    attempt: string;
    status: string;
}


@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {
    public contactForm: FormGroup;
    public currentlySaving = false;


    constructor(
        private fb: FormBuilder,
        private http: HttpClient,
        public dialog: MatDialog,
        private router: Router) {
    }

    ngOnInit() {
        this.setForm();
    }

    public setForm() {
        this.contactForm = this.fb.group({
            first_name: ['', [
                Validators.required,
                Validators.minLength(2)
            ]],
            last_name: ['', [
                Validators.required,
                Validators.minLength(2)
            ]],
            email: ['', [
                Validators.required
            ]],
            phone: ['', [
                Validators.required,
                // Validators.minLength(10)
            ]],
            message: ['', [
                Validators.required
            ]]
        });
    }

    public save() {
        this.currentlySaving = true;

        const data = new FormData();
        // const data = {};

        const httpOptions = {
            headers: new HttpHeaders({
                // 'Content-Type': 'application/x-www-form-urlencoded',
                // 'Content-Type': 'multipart/form-data',
            })
        };


        Object.keys(this.contactForm.controls).forEach((control) => {
            // data[control] = this.contactForm.get(control).value;
            data.append(control, this.contactForm.get(control).value);
        });

        // this.http.post<ResponseObject>('https://hooks.zapier.com/hooks/catch/8058191/oz0fcbe', data, httpOptions).subscribe((res) => {
        this.http.post<ResponseObject>('https://wh.automate.io/webhook/5f258a45bfb9cd60aa43b586', data).subscribe((res) => {
            this.currentlySaving = false;

            if (res.status === 'success') {
                // some type of success dialoge should happen
                this.openSuccessModal();
            }

        });

    }

    public openSuccessModal() {
        const passed = {
            title: `Email Sent Successfully`,
            message: `Thanks for getting in touch with us, we'll touch base with you soon.`,
            standardClose: false,
            buttons: [
                {
                    label: 'Ok',
                    returnLabel: 'ok',
                    selected: true
                }
            ]
        };
        const dialogRef = this.dialog.open(StandardDialogComponent, {
            width: '350px',
            data: passed
        });

        dialogRef.afterClosed().subscribe((result) => {
            switch (result) {
                case 'ok':
                    this.router.navigate(['/about']);
                    break;
                default:
                    // Silent Close
                    break;
            }
        });
    }

}
