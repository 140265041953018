import {Component, OnInit, Injectable} from '@angular/core';
import {POSTS_MODULE} from '../core/posts.module';

import {
    Resolve,
    Router,
    ActivatedRouteSnapshot,
    ActivatedRoute,
    RouterStateSnapshot
} from '@angular/router';


@Component({
    selector: 'app-post',
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
    public postSlug: string;
    public blogPosts = [];
    public post = [];

    constructor(private _route: ActivatedRoute) {
    }

    ngOnInit() {
        this.blogPosts = POSTS_MODULE;

        this._route.params.subscribe((res) => {
            if (res) {
                console.log('res', res);
                this.postSlug = res.slug;
                this.setPost();
            }
        });
    }

    public setPost() {
        this.post = this.blogPosts.filter(b => b.slug === this.postSlug)[0];
        console.log('this.postSlug', this.postSlug);
        console.log('this.blogPosts', this.blogPosts);
        console.log('this.post', this.post);
    }

}
