import {
    Component,
    Inject
} from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

@Component({
    selector: 'app-standard-dialog',
    templateUrl: 'standard-dialog.component.html',
})
export class StandardDialogComponent {

    public constructor(
        public dialogRef: MatDialogRef<StandardDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data['standardClose'] === undefined) {
            data.standardClose = true;
        }
    }
}
