import {NgModule} from '@angular/core';

export const POSTS_MODULE = [
    {
        title: 'Why Hire A Professional?',
        image: '/assets/images/duck.jpg',
        body: '<p>There are many reasons why you should hire a professional. For instance, only a true developer or someone who has programmed for years will know why the image of this duck has any sort of relevance at all. If they have programmed for long enough, they will have surely spent hours having conversations with this duck and maybe even yelling at this duck. You cannot pay enough for experience or for someone who knows how to troubleshoot. Troubleshooting is something you don\'t learn in schools, it only comes with experience.</p>' +
            '<p>A real professional is going to leave you with a finished product that not only meets all the requirements, but also is something you can manage yourself. Too often the problem with trying to do something yourself or pay your friends cousin to do a super cheap job, is you end up with something that has no real value and doesn\'t last long. Before you know it, you\'ll run into problems and end up needing to pay someone to fix it, or even make you a new website. Had it have been made properly, you would have received a product that will last longer and hold up in the ever changing world that is technology.</p>' +
            '<p>A professional will know and incorporate all the details that make sense for your project. Proper seo plays on schema tags, linking both internal and external, titles and metas, alt tags, any sort of accessibility complaiance, etc. Knowing what are things you need to purchase and where you can get high quality free assets. Making sure your software responds well on a phone or a tablet as well as on a 8K mega huge television screen. There are so many pieces involved to make a high polished product, I wouldn\'t trust someone who had barely any experience. You would never treat other areas of your life that way, and the chances are good that if you need a website or any type of application, it\'s probably for your business or some other import need.</p>' +
            '<p>When I bought my first house, I needed to get the exterior painted. I spend a week calling all over town trying to get as many quotes as I could. For the most part, the quotes were all in the same ball park but I did get 1 quote that was really low, incredibly low. When I asked one of my friends who painted for a living about it, he explained to me the differences between a $5,000 paint bid and a $1,000 paint bid. You get what you pay for, and they may look the same from a distance, but as soon as you try to move in and get close, you\'ll wish you had the $5,000 paint job and you can see right away what makes it better. Don\'t be the person with the $1,000 paint job, you can thank us later!</p>',
        created_on: '7/18/2020',
        category: 'Development',
        tags: 'Code, Agency, Programmer',
        author: 'JD Antosiak',
        slug: 'why-hire-a-developer'
    },
    {
        title: 'Getting A Basic Web Presence',
        image: '/assets/images/starting-point.jpg',
        body: '<p>For starters, you\'ll want to make sure you hire a professional to get your site put together. They\'re going to have you in a nice position right from the beginning by doing all the small things like naming images properly, alt text, titles, metas, etc. If none of these things ring a bell, feel free to read about <a href="/blog/why-hire-a-developer">why hiring a developer or professional team is the right step to get started</a>. The best thing you can do up front is make sure you have a solid base to work from, after that you can tackle all the other technical areas that you can leverage.</p>' +
            '<p>Another simple thing you can do is setup some basic local listings management. This is essentially just claiming and hitting the more popular search areas. Set up a Google My Business, A Bing Places Business, and a Yahoo Business listing are an easy entry point. When it feels familiar you can branch out into other listings management if you feel like you need more traction. Depending on what kind of traffic you get to your site, you may not even need anything else.</p>' +
            '<p>Adding Google Analytics is a no-brainer. You\'re going to want to know how many people are seeing your site. Even if it\'s just basic use, you can get a lot of valuable information from Analytics without much background knowledge. You can easily see where people are coming from, what pages they spend the most time on, where they leave your site from, bounce rates for the average user, and how many visitors are returning. That information alone is worth the effort. In no time at all you can Analytics on your site and start gaining data on your users.</p>' +
            '<p>Lastly, you can submit your site maps to major search engines, Google, Bing, and Yahoo to name a few. You\'ll also want to get some type of social accounts up and running, even if you aren\'t making daily posts, whatever you can do to add more streams for your users to find you. Then once analytics starts to acquire data, you\'ll be able to see which social accounts are working out well for your audience. This is just a nice starting point to help your website start to work more efficiently for you.</p>',
        created_on: '7/22/2020',
        category: 'Marketing',
        tags: 'Web, SEO, Local, Google',
        author: 'JD Antosiak',
        slug: 'getting-a-basic-web-presence'
    }
];

@NgModule({
    // imports: POSTS_MODULE,
    // declarations: [],
    // exports: POSTS_MODULE,
})
export class PostsModule {
}
